<template>
  <div class="poi-picker">
    <div class="picker-header">
      <div class="picker-btn">
        <div class="cancel" @click="onCancel">取消</div>
        <div
          class="confirm"
          :class="{ disabled: !selectedPOI }"
          @click="onConfirm"
        >
          确定
        </div>
      </div>
      <div class="picker-title">
        <div class="aoi-title">AOI</div>
        <div class="poi-title">POI</div>
      </div>
    </div>
    <div class="picker-body">
      <ul class="aoi-list">
        <li
          v-for="item in dataList"
          :key="item.parkCode"
          @click="onSelectAOI(item)"
          :class="{ active: selectedAOI?.parkCode === item.parkCode }"
        >
          {{ item.parkName }}
        </li>
      </ul>
      <ul class="poi-list" v-if="selectedAOI">
        <li
          v-for="item in selectedAOI.poiList"
          :key="item.stationId"
          :class="{ active: selectedPOI?.stationId === item.stationId }"
          @click="onSelectPOI(item)"
        >
          {{ item.stationName }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: ['selected', 'dataList'],
  data() {
    return {
      selectedAOI: null,
      selectedPOI: null,
      poiList: [],
      timestamp: Date.now(),
    };
  },
  watch: {
    selected: {
      handler(value, old) {
        if (old === undefined && value[0]) {
          this.selectedAOI = value[0];
          this.selectedPOI = value[1];
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelectAOI(item) {
      if (this.selectedAOI?.parkCode === item.parkCode) return;
      this.selectedAOI = item;
      this.selectedPOI = null;
    },
    onSelectPOI(item) {
      this.selectedPOI = item;
    },
    onConfirm() {
      if (!this.selectedPOI) return;
      this.$emit('confirm', [this.selectedAOI, this.selectedPOI]);
    },
    onCancel() {
      this.$emit('cancel');
      // 如果点了取消，把上次数据回显
      if (this.selected && this.selected.length && this.selected[0]) {
        this.selectedAOI = this.selected[0];
        this.selectedPOI = this.selected[1];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.poi-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  .picker-header {
    height: 84px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fafafa;
    .picker-btn {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      > div {
        height: 100%;
        line-height: 42px;
      }
      .confirm {
        color: rgba(67, 110, 255, 1);
        &.disabled {
          color: rgba(151, 152, 171, 1);
        }
      }
    }

    .picker-title {
      height: 42px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      gap: 16px;
      .aoi-title {
        flex: 2 0 40%;
      }
      .poi-title {
        flex: 3 1 60%;
        width: 58%;
      }
    }
  }
  .picker-body {
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    overflow: hidden;
    gap: 16px;
    .aoi-list {
      flex: 2 0 40%;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        box-sizing: border-box;
        padding: 12px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.active {
          color: #465ffd;
        }
      }
    }

    .poi-list {
      flex: 3 1 60%;

      overflow: auto;
      li {
        box-sizing: border-box;
        padding: 12px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.active {
          color: #465ffd;
        }
      }
    }
  }
}
</style>
