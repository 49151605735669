<template>
  <div class="parking-tool">
    <div class="header">
      <div class="back-btn" @click="back">
        <img
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
      </div>
      <div class="poi-selector-trigger" @click="show = true">
        <span v-if="!selectedPOI">请选择POI</span>
        <span class="stationName" v-else>{{ selectedPOI.stationName }}</span>
        <img
          class="arrow-down"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/arrow-down.png"
          alt=""
        />
      </div>
    </div>
    <div class="content">
      <van-pull-refresh
        class="pull-refresh"
        v-model="isRefreshing"
        success-text="刷新成功"
        @refresh="onRefresh"
        :disabled="!selectedPOI"
      >
        <div class="content-inner">
          <div v-if="!selectedPOI" class="none-data">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
              alt=""
            />
            <span>请先选择一个POI</span>
          </div>
          <div v-else-if="count" class="parking-wrap">
            <div class="parking-tabs">
              <div @click="onTabClick(0)" :class="{ active: queryType === 0 }">
                <span>全部</span>
                <span>{{ count.using + count.idle }}</span>
              </div>
              <div @click="onTabClick(1)" :class="{ active: queryType === 1 }">
                <span>空闲</span>
                <span>{{ count.idle }}</span>
              </div>
              <div @click="onTabClick(2)" :class="{ active: queryType === 2 }">
                <span>使用中</span>
                <span>{{ count.using }}</span>
              </div>
            </div>
            <ul v-if="parkingInfo && parkingInfo.length" class="parking-list">
              <li
                v-for="item in parkingInfo"
                :key="item.parkingNo"
                class="parking-item"
              >
                <div class="parking-item-header">
                  <div class="parking-item-info">
                    <div class="parkingName">{{ item.parkingName }}</div>
                    <div class="parkingNo">id {{ item.parkingNo }}</div>
                  </div>
                  <div class="parking-item-status">
                    <div v-if="isUsing(item)" class="itemUsing">使用中</div>
                    <div v-else class="itemIdle">闲置</div>
                  </div>
                </div>
                <ul v-if="isUsing(item)" class="parking-item-content">
                  <li v-for="vinId in item.occupiedVinList" :key="vinId">
                    <div class="vinId">{{ vinId }}</div>
                    <div class="subUsing">占用</div>
                  </li>
                  <li v-for="vinId in item.parkingInVinList" :key="vinId">
                    <div class="vinId">{{ vinId }}</div>
                    <div class="subIn">泊入中</div>
                  </li>
                  <li v-for="vinId in item.parkingOutVinList" :key="vinId">
                    <div class="vinId">{{ vinId }}</div>
                    <div class="subOut">泊出中</div>
                  </li>
                </ul>
              </li>
            </ul>
            <div v-else class="none-data">
              <img
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
                alt=""
              />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            v-else-if="count === null && parkingInfo?.length === 0"
            class="none-data"
          >
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/no_data.png"
              alt=""
            />
            <span>暂无数据</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <van-popup
      :duration="0.2"
      v-model="show"
      round
      position="bottom"
      :style="{ height: '80%' }"
      :close-on-click-overlay="false"
    >
      <poi-picker
        @confirm="pickerChange"
        @cancel="show = false"
        :dataList="dataList"
        :selected="selectedAddr"
      ></poi-picker>
    </van-popup>
  </div>
</template>

<script>
import PoiPicker from './components/poiPicker.vue';
import { getParkingPoi, queryParkingStatus } from '@/api/apiv2';

export default {
  components: { PoiPicker },
  data() {
    return {
      show: false,
      // selectedPOI: { stationId: 124792 },
      selectedPOI: null,
      selectedAOI: null,
      parkingData: null,
      isRefreshing: false,
      queryType: 0, // 0: 全部 1: 空闲 2: 占用
      dataList: [],
      count: null,
      parkingInfo: null,
      timer: null,
      loading: false,
      selectedAddress: null,
      selectedAddr: [],
    };
  },
  methods: {
    pickerChange([AOI, POI], cache) {
      this.selectedPOI = POI;
      this.selectedAOI = AOI;
      this.show = false;
      this.queryType = 0;
      this.getParkingStatus();
      if (cache !== true) {
        this.utils.setLocalStorageDataByApp(JSON.stringify({ AOI, POI }));
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getParkingStatus(false);
      }, 3000);
    },
    onRefresh() {
      this.getParkingStatus(false);
    },
    getParkingStatus(loading = true) {
      if (loading) {
        this.loading = true;
        this.$loadingCircle.start();
      }
      queryParkingStatus({
        stationId: this.selectedPOI.stationId,
        queryType: this.queryType,
      })
        .then((res) => {
          this.count = res.count;
          this.parkingInfo = res.parkingInfo;
        })
        .finally(() => {
          if (loading) {
            this.$loadingCircle.end();
            this.loading = false;
          }
          this.isRefreshing = false;
        });
    },
    onTabClick(key) {
      if (this.loading || this.isRefreshing) return;
      if (key === this.queryType) return;
      this.queryType = key;
      this.getParkingStatus();
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getParkingStatus(false);
      }, 3000);
    },
    isUsing(item) {
      return (
        item.occupiedVinList.length !== 0 ||
        item.parkingInVinList.length !== 0 ||
        item.parkingOutVinList.length !== 0
      );
    },
    back() {
      window.location.href = 'neolix://more';
    },
  },
  created() {
    getParkingPoi().then((res) => {
      this.dataList = res;
    });

    window.getParkingToolFilter = (cache) => {
      if (cache) {
        const { POI, AOI } = cache;
        this.selectedAddr = [AOI, POI];
        this.pickerChange([AOI, POI], true);
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.parking-tool {
  font-size: initial;
  background: #f6f7f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    height: 40px;
    background: white;
    padding: 0 8px 0 10px;
    box-sizing: border-box;
    .back-btn {
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }

    .poi-selector-trigger {
      margin-left: 7px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      background: #f6f7f8;
      border-radius: 6px;

      span {
        margin-left: 9px;
        color: rgba(151, 152, 171, 1);
        font-size: 14px;
        &.stationName {
          color: rgba(0, 0, 0, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .arrow-down {
        width: 14px;
        height: 14px;
        margin-right: 9px;
      }
    }
  }

  .content {
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 40px);

    .pull-refresh {
      min-height: 100%;
    }

    .content-inner {
      overflow: auto;
      .none-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        img {
          width: 130px;
          height: 130px;
          margin-top: 30%;
          margin-bottom: 15px;
        }
      }

      .parking-tabs {
        display: flex;
        box-sizing: border-box;
        margin: 14px;
        align-items: center;
        justify-content: space-between;
        > div {
          width: 109px;
          height: 64px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 4px;
          box-sizing: border-box;
          color: #9798ab;
          font-weight: 600;
          &.active {
            border: 2px solid #426eff;
            span {
              color: black;
            }
          }
          > span {
            &:nth-child(1) {
              font-size: 14px;
            }

            &:nth-child(2) {
              font-size: 22px;
              line-height: 22px;
            }
          }
        }
      }

      .parking-list {
        margin: 0 14px;
        box-sizing: border-box;
        padding: 0;
        .parking-item {
          padding: 14px;
          box-sizing: border-box;
          background: white;
          border-radius: 12px;
          margin-bottom: 14px;
          &-header {
            display: flex;
            justify-content: space-between;
            .parking-item-info {
              .parkingName {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0px;
                color: rgba(0, 0, 0, 1);
              }

              .parkingNo {
                margin-top: 6px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                color: rgba(151, 152, 171, 1);
              }
            }

            .parking-item-status {
              > div {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0px;
                &.itemUsing {
                  color: rgba(255, 57, 27, 1);
                }
                &.itemIdle {
                  color: rgba(38, 197, 117, 1);
                }
              }
            }
          }
          &-content {
            margin-top: 8px;
            padding: 14px;
            box-sizing: border-box;
            background: #f7f9fa;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:not(:last-child) {
                margin-bottom: 8px;
              }

              > div {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0px;
              }

              .vinId {
                color: rgba(0, 0, 0, 1);
              }

              .subUsing {
                color: rgba(255, 57, 27, 1);
              }

              .subIn,
              .subOut {
                color: rgba(66, 110, 255, 1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
